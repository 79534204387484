import "../../scss/custom/Sidebar.scss";

// импорты компонентов 
import Details from "../basic/Details";
import FontSelector from "./sidebar/FontSelector";
import TextFormattingButtons from "./sidebar/TextFormattingButtons";
import ListFormattingButtons from "./sidebar/ListFormattingButtons";
import HiddenSymbolsToggle from "./sidebar/HiddenSymbolsToggle";
import DocumentViewButtons from "./sidebar/DocumentViewButtons";
import InsertSelect from "./sidebar/InsertSelect";
import PageCounter from "./sidebar/PageCounter";

// импорты хуков
import { useFonts } from "./hooks/useFonts";
import { useTextFormatting } from "./hooks/useTextFormatting";


const EditorLeftSidebar = ({ onFontChange, wordCount, pages, currPage }) => {
  const { fontFamily, fontSize, searchTerm, setSearchTerm, filteredFonts, handleFontChange, handleFontSizeChange } = useFonts(onFontChange);
  const { fontWeight, fontStyle, textDecoration, handleBoldClick, handleItalicClick, handleUnderlineClick } = useTextFormatting();

  return (
    <aside className="aside aside--editor">
      <Details title={'Шрифт'}>

      <FontSelector
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          fontFamily={fontFamily}
          fontSize={fontSize}
          filteredFonts={filteredFonts}
          handleFontChange={handleFontChange}
          handleFontSizeChange={handleFontSizeChange}
        />

        <TextFormattingButtons 
          fontWeight={fontWeight} 
          fontStyle={fontStyle} 
          textDecoration={textDecoration} 
          onBoldClick={handleBoldClick} 
          onItalicClick={handleItalicClick} 
          onUnderlineClick={handleUnderlineClick} 
        />

      </Details>

      <Details title={'Список'}>
        <ListFormattingButtons />
      </Details>

      <Details title={'Скрытые символы'}>
        <HiddenSymbolsToggle />
      </Details>

      <Details title={'Отображение документов'}>
        <DocumentViewButtons />
      </Details>

      <Details title={'Вставка'}>
        <InsertSelect />
      </Details>

      <PageCounter wordCount={wordCount} page={pages} currPage={currPage}/>
    </aside>
  );
};

export default EditorLeftSidebar;
