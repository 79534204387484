import React, { useState } from "react";
import Header from "../components/Header";
import EditorLeftSidebar from "../components/editor/EditorLeftSidebar";
import EditorRightSidebar from "../components/editor/EditorRightSidebar";
import Editor from "../components/editor/Editor";

const EditorLayout = () => {
  const [fontFamily, setFontFamily] = useState("Roboto");
  const [fontSize, setFontSize] = useState(14);
  const [pages, setPages] = useState(0)
  const [currPage, setCurPage] = useState(0)
  const [wordCount, setWordCount] = useState(0); //Счётчик слов
  const [fontWeight, setFontWeight] = useState(400);
  const [fontStyle, setFontStyle] = useState("normal");
  const [textDecoration, setTextDecoration] = useState("none");

  return (
    <div>
      <Header />
      <main style={{ display: 'flex', justifyContent: "space-between", backgroundColor: '#313131' }}>
        <EditorLeftSidebar
          onFontChange={setFontFamily}
          onFontSizeChange={setFontSize}
          onFontWeightChange={setFontWeight}
          onFontStyleChange={setFontStyle}
          onTextDecorationChange={setTextDecoration}
          wordCount={wordCount}
          pages={pages}
          currPage={currPage}
        />
        <Editor
          fontFamily={fontFamily}
          fontSize={fontSize}
          fontWeight={fontWeight}
          textDecoration={textDecoration}
          fontStyle={fontStyle}
          onWordCountChange={setWordCount}
          setPagesTotal={setPages}
          setCurPage={setCurPage}
        />
        <EditorRightSidebar />
      </main>
    </div>
  );
};

export default EditorLayout;
