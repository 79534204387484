import { useState, useEffect, useRef } from "react";
import "../../scss/custom/Editor.scss";

const PAGE_HEIGHT = 800; // Высота страницы
const PAGE_WIDTH = 600; // Ширина страницы

const Editor = ({
  fontFamily = "Arial",
  fontSize = 16,
  onWordCountChange,
  fontWeight = "normal",
  textDecoration = "none",
  fontStyle = "normal",
  setPagesTotal,
  setCurPage,
}) => {
  const [pages, setPages] = useState([""]); // Каждая страница хранит строку текста
  const containerRef = useRef(null);
  const pageRefs = useRef([]);

  const handleTextChange = (index, event) => {
    const currentText = event.target.innerText;

    setPages((prev) => {
      const newPages = [...prev];
      newPages[index] = currentText;

      // Проверка на переполнение страницы
      if (pageRefs.current[index] && pageRefs.current[index].scrollHeight > PAGE_HEIGHT) {
        const overflowText = currentText.slice(-1);
        newPages[index] = currentText.slice(0, -1);

        if (index === newPages.length - 1) {
          newPages.push(overflowText); // Добавляем новую страницу для переполненного текста
        } else {
          newPages[index + 1] = (newPages[index + 1] || "") + overflowText;
        }

        setTimeout(() => {
          if (pageRefs.current[index + 1]) {
            const nextPage = pageRefs.current[index + 1];
            setCursorAtEnd(nextPage);
          }
        }, 0);
      }

      return newPages;
    });

    updateWordCount();
  };

  const handleBackspace = (index, event) => {
    if (pages[index].length === 0 && index > 0) {
      setPages((prev) => {
        const newPages = [...prev];
        const previousText = newPages[index - 1] || "";

        newPages[index - 1] = previousText + pages[index]; // Переносим текст на предыдущую страницу
        newPages.splice(index, 1); // Удаляем текущую страницу

        setTimeout(() => {
          if (pageRefs.current[index - 1]) {
            pageRefs.current[index - 1].focus();
            setCursorAtEnd(pageRefs.current[index - 1]);
          }
        }, 0);

        return newPages;
      });

      event.preventDefault();
    }

    updateWordCount();
  };

  const updateWordCount = () => {
    const totalWords = pages.reduce(
      (sum, page) => sum + page.split(/\s+/).filter(Boolean).length,
      0
    );
    onWordCountChange(totalWords);
  };

  const setCursorAtEnd = (element) => {
    if (element) {
      const range = document.createRange();
      const sel = window.getSelection();
      range.selectNodeContents(element);
      range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(range);
      element.focus();
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
    setPagesTotal(pages.length);
  }, [pages.length]);

  return (
    <div className="editor-container">
      <div
        className="document-container"
        ref={containerRef}
        style={{
          overflowY: "auto",
          maxHeight: "100vh",
          padding: "10px",
          backgroundColor: "transparent",
        }}
      >
        {pages.map((pageContent, index) => (
          <div
            key={index}
            ref={(el) => {
              if (el) {
                pageRefs.current[index] = el;
              }
            }}
            className="document-page"
            contentEditable
            suppressContentEditableWarning
            style={{
              height: `${PAGE_HEIGHT}px`,
              width: `${PAGE_WIDTH}px`,
              overflow: "hidden",
              fontFamily,
              fontSize: `${fontSize}px`,
              fontWeight,
              textDecoration,
              fontStyle,
              whiteSpace: "pre-wrap",
              padding: "20px",
              boxSizing: "border-box",
              marginBottom: "10px",
              background: "white",
              color: "black",
            }}
            onInput={(e) => handleTextChange(index, e)}
            onClick={() => setCurPage(index + 1)}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                handleBackspace(index, e);
              }
            }}
          >
           
          </div>
        ))}
      </div>
    </div>
  );
};

export default Editor;
