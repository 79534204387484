import '../scss/custom/Main.scss'
import DocumentsPage from './Documents'

const MainContent = ({projects, userId}) => {
    return (
        <div className='main'>
            {projects.length > 0
            ? 
            <DocumentsPage projects={projects} userId={userId}/>
            : 
            <div></div>}
        </div>
    )
}

export default MainContent