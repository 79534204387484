import React, { useEffect, useState } from 'react';
import '../scss/custom/BigDoc.scss';
import docimg from '../assets/images/doc.png';
import iconuser from '../assets/icons/iconUser.svg';

const BigDoc = ({ dataId, userId, projectName, onClick }) => {
  const [dataType, setDataType] = useState('');

  useEffect(() => {
    if (dataId === userId) {
      setDataType('my');
    } else {
      setDataType('multy');
    }
  }, [dataId, userId]);

  return (
    <li className="bigdoc" data-id={dataId} data-type={dataType} onClick={onClick}>
      <img src={docimg} alt="Document icon" className='bigdoc__preview'/>
      <p>{projectName}</p>
      <p><span><img src={iconuser} alt="user icon" /></span> <span className='bigdoc__lastchange'>3 месяца назад</span></p>
    </li>
  );
};

export default BigDoc;
