import "../../scss/custom/Sidebar.scss";
import Details from "../basic/Details";
import { useParams } from "react-router-dom";
import { useState } from "react";

const EditorRightSidebar = () => {
  const { project_id } = useParams(); // Получаем project_id из URL
  const [projectName, setProjectName] = useState(""); // Локальное состояние для имени проекта

  const handleSaveProject = async () => {
    const accessToken = localStorage.getItem("accesstoken");

    if (!accessToken) {
      console.error("Ошибка: отсутствует токен авторизации");
      return;
    }

    if (!project_id) {
      console.error("Ошибка: отсутствует project_id");
      return;
    }

    const url = `https://gostdocapi.computernetthings.ru/projects/${project_id}`;
    const payload = { name: projectName };

    console.log("Project ID:", project_id); // Для отладки
    console.log("Payload:", payload);       // Для отладки

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Ошибка обновления: ${response.status} ${response.statusText}`);
      }

      const result = await response.json();
      console.log("Проект успешно обновлён:", result);

      // Обновляем локальное состояние после успешного обновления на сервере
      setProjectName(result.name || projectName); // Если сервер возвращает новое имя
    } catch (error) {
      console.error("Ошибка при сохранении проекта:", error);
    }
  };

  return (
    <aside className="aside aside--editor">
      <>
        <Details title={"Сохранение проекта"}>
          <input
            type="text"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)} // Обновляем состояние при изменении
            placeholder="Введите новое имя проекта"
          />
          <button
            className="aside__button-save button--save-on-site"
            onClick={handleSaveProject}
          >
            Сохранить
          </button>
        </Details>
        <Details title={"Печать"}>
          <ul></ul>
        </Details>
        <Details title={"Параметры документа"}>
          <ul></ul>
        </Details>
      </>
    </aside>
  );
};

export default EditorRightSidebar;
