import React, { useState } from "react";
import Button from "./basic/Button";
import { ReactComponent as PlusIcon } from "../assets/icons/iconPlus.svg";
import "../scss/custom/ProjectModal.scss";

const ProjectModal = ({ onClose, onSubmit }) => {
  const [projectName, setProjectName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (projectName) {
      onSubmit(projectName);
      onClose();
    }
  };

  return (
    <div className="modal">
      <div className="modal__content">
        <h3 className="modal__title">Добавить новый проект</h3>
        <button onClick={onClose} className="modal__button">
            <PlusIcon />
        </button>
        <form className="modal__form" onSubmit={handleSubmit}>
          <label htmlFor="name" className="modal__label">
            Название проекта
            <input
              className="modal__input"
              type="text"
              name="name"
              id="name"
              placeholder="Название проекта"
              autoComplete="off"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
          </label>
          <Button type="submit" modifier="button--primary">
            Создать
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ProjectModal;
