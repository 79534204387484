import { ReactComponent as PalletIcon } from "../assets/icons/iconPallete.svg";
import { ReactComponent as ListViewIcon } from "../assets/icons/IconListView.svg";
import BigDoc from "./BigDoc";
import "../scss/custom/Documents.scss";
import { useState } from "react";
import SmallDoc from "./SmallDoc";
import { useNavigate } from "react-router";

const DocumentsPage = ({ projects, userId }) => {
  const [view, setView] = useState("pallete");
  const [typeOfList, setTypeOfList] = useState("all");
  const navigate = useNavigate();
  console.log('projects in documents - ', projects);

  const handleChangeView = (view) => {
    setView(view);
  };

  const handleChangeTypeOfList = (type) => {
    setTypeOfList(type);
  };

  const filteredProjects = projects.filter((project) => {
    if (typeOfList === "my") {
      return project.owner_id === userId;
    } else if (typeOfList === "multy") {
      return project.owner_id !== userId;
    }
    return true;
  });

  projects.reverse();

  return (
    <div className="documents">
      <div className="documents__header">
        <ul className="documents__header--list">
          <li onClick={() => handleChangeTypeOfList("all")} className={typeOfList === "all" ? "active" : ""}>
            Все
          </li>
          <li onClick={() => handleChangeTypeOfList("my")} className={typeOfList === "my" ? "active" : ""}>
            Мои документы
          </li>
          <li onClick={() => handleChangeTypeOfList("multy")} className={typeOfList === "multy" ? "active" : ""}>
            Совместный доступ
          </li>
        </ul>
        <ul className="documents__header--view">
          <li>Название</li>
          <li onClick={() => handleChangeView("pallete")} className={view === "pallete" ? "active" : ""}>
            <PalletIcon />
          </li>
          <li onClick={() => handleChangeView("row")} className={view !== "pallete" ? "active" : ""}>
            <ListViewIcon />
          </li>
        </ul>
      </div>
      <div className="documents__list">
        {view === "pallete" ? (
          <ul>
            {filteredProjects.map((project) => (
              <BigDoc key={project.id} project={project} dataId={project.owner_id} userId={userId} projectName={project.name} onClick={() => navigate(`/editor/${project.id}`)}/>
            ))}
          </ul>
        ) : (
          <>
            <div className="documents__list--sort">
              <p>Название</p>
              <p>Последнее изменение</p>
              <p>Создан</p>
            </div>
            <ul>
              {filteredProjects.map((project) => (
                <SmallDoc key={project.id} project={project} dataId={project.owner_id} userId={userId} projectName={project.name} onClick={() => navigate(`/editor/${project.id}`)}/>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default DocumentsPage;
