import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../assets/icons/iconHome.svg";
import { ReactComponent as PlusIcon } from "../assets/icons/iconPlus.svg";
import "../scss/custom/header.scss";
import Search from "./basic/Search.jsx";
import Button from "./basic/Button.jsx";
import iconUser from "../assets/icons/iconUser.svg";
import uploadIcon from "../assets/icons/iconUpload.svg";
import ProjectModal from "./ProjectModal.jsx";

const Header = ({createProjects}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const location = useLocation();
  const isDocsPage = location.pathname === "/docs";
  const isEditorPage = location.pathname.startsWith('/editor/');

  const handleProjectCreate = (projectName) => {
    createProjects(projectName);
  };

  return (
    <header className="header">
      <div className="header__static">
        <Link to="/docs" className="header__home">
          <HomeIcon
            style={{
              fill: "rgb(245, 245, 245)",
              width: "14px",
              height: "14px",
            }}
          />
        </Link>
        <Search placeholder={isDocsPage ? "Поиск документов" : "Поиск"} />
      </div>
      {isDocsPage ? (
        <div className="header__docs">
          <h2>Все документы</h2>
          <div className="header__buttons">
            <Button modifier=" button--primary" onClick={openModal}>
              <PlusIcon />
              Документ
            </Button>
            <Button modifier=" button--secondary">Загрузить</Button>
          </div>
        </div>
      ) : isEditorPage ? (
        <div className="header__editor">
          <div className="header__nameless-wrapper">
            <h2 className="header__name-doc">Документ</h2>
            <button className="header__upload-button">
              <img
                className="header__upload-image"
                src={uploadIcon}
                alt="Загрузить"
              />
            </button>
          </div>
          <div className="header__buttons">
            <img
              className="header__user-image"
              src={iconUser}
              alt="Иконка пользователя"
            />
            <Button modifier=" button--primary">Поделиться</Button>
          </div>
        </div>
      ) : null}

      {isModalOpen && (
        <ProjectModal onClose={closeModal} onSubmit={handleProjectCreate} />
      )}
    </header>
  );
};

export default Header;
