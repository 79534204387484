import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import MainContent from "../components/MainContent";
import { jwtDecode } from "jwt-decode";

const MainLayout = () => {
  const [projects, setProjects] = useState([]);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const access_token = localStorage.getItem("accesstoken");
    if (!access_token) {
      console.log("Пользователь не найден");
      return;
    } else {
      const decodedToken = jwtDecode(access_token);
      setUserId(decodedToken.sub);
    }
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const access_token = localStorage.getItem("accesstoken");
        const response = await fetch(
          "https://gostdocapi.computernetthings.ru/projects/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setProjects(data);
        } else if (response.status === 404) {
          const errorData = await response.json();
          if (errorData.detail === "No projects found for this user") {
            console.log("No projects found for this user");
            setProjects([]);
          } else {
            console.error("Неизвестная 404 ошибка", errorData);
          }
        } else {
          console.error("Ошибка при получении проектов со статусом:", response.status);
        }
      } catch (error) {
        console.error("Ошибка при загрузке проектов", error);
      }
    };

    fetchProjects();
  }, []);

  const createProjects = async (projectName) => {
    const access_token = localStorage.getItem("accesstoken");
    if (!access_token) {
      console.log("Пользователь не найден");
      return;
    }

    try {
      const response = await fetch(
        "https://gostdocapi.computernetthings.ru/projects/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          body: JSON.stringify({ name: projectName }),
        }
      );

      if (response.ok) {
        const newProject = await response.json();
        setProjects((prevProjects) => [...prevProjects, newProject]);
        console.log("Проект создан:", newProject);
      } else {
        console.error("Не удалось создать проект");
      }
    } catch (error) {
      console.error("Ошибка", error);
    }
  };


  return (
    <div className="layout">
      <Header createProjects={createProjects} />
      <main>
        <Sidebar />
        <MainContent projects={projects} userId={userId} />
      </main>
    </div>
  );
};

export default MainLayout;
