import React from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import '../scss/custom/Button.scss';
import '../scss/custom/NotFound.scss';


const NotFound = () => {
    return (
        <div>
            <Header />
            <main >
                <div className="not-found">
                    <p className="not-found__problem">404</p>
                    <h1 className="not-found__title">Документ не найден</h1>
                    <p className="not-found__description">Этот документ был удален, перемещен или вовсе не существует</p>
                    <Link to="/docs" className="button button--primary not-found__link">Вернуться на главную</Link>
                </div>
            </main>
        </div>
    )
}

export default NotFound