import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../scss/custom/auth.scss";
import { ReactComponent as IconLogo } from "../assets/icons/iconLogo.svg";
import { ReactComponent as IconPasswordShow } from "../assets/icons/iconPasswordShow.svg";
import Button from "./basic/Button";

const Login = () => {
  const [showPass, setShowPass] = useState(false);
  const [state, setState] = useState({
    email: "",
    name: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const login = async (e) => {
    e.preventDefault();
    console.log(state);
    const response = await fetch(
      "https://gostdocapi.computernetthings.ru/user/signin",
      {
        method: "POST",
        
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(state),
      }
    );
    if (!response.ok) {
      const errorText = response;
      console.log(errorText);
      return;
    }

    const data = await response.json();
    localStorage.setItem('accesstoken', data.access_token)
    localStorage.setItem('refreshtoken', data.refresh_token)
    console.log("Регистрация успешна! ", data);
    navigate("/docs"); //Переводим на листинг документов при успешной авторизации
  };

  return (
    <section className="auth">
      <div className="auth__container">
        {/* Логотип */}
        <IconLogo className="auth__logo" />

        <div className="auth__content-wrapper">
          <div className="auth__title-wrapper">
            <h1 className="auth__title">Вход</h1>
            <a href="/signup" className="auth__link">
              Нет аккаунта? Создать
            </a>
          </div>

          {/* Форма для входа */}
          <form className="auth__form" onSubmit={login}>
            {/* Логин */}
            <label className="auth__label">
              Логин
              <input
                type="text"
                name="name"
                value={state.login}
                onChange={handleChange}
                placeholder="Имя пользователя или почта"
                className="auth__input"
                autoComplete="off"
              />
            </label>

            {/* Пароль */}
            <label className="auth__label">
              Пароль
              <div className="auth__input-wrapper">
                <input
                  type={showPass ? "text" : "password"}
                  name="password"
                  value={state.password}
                  onChange={handleChange}
                  placeholder="Ваш пароль"
                  className="auth__input auth__input--with-icon"
                  autoComplete="off"
                />
                {/* Ссылка на восстановление пароля */}
                <a href="/password-reset" className="auth__link auth__forgot-link">
                  Забыли?
                </a>
                {/* Иконка для показа/скрытия пароля */}
                <IconPasswordShow
                  className="auth__icon"
                  onClick={() => setShowPass(!showPass)}
                />
              </div>
            </label>

            {/* Кнопка Войти */}
            <Button type="sumbit" modifier={" button--primary mt-10"}>
              Войти
            </Button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Login;
